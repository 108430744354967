import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import loadable from "@loadable/component"

const Layout = loadable(() => import("../components/Layout"))
const Seo = loadable(() => import("../components/Seo"))
const Banner = loadable(() => import("../components/HomePage/Banner"))
const KeyFeatures = loadable(() => import("../components/HomePage/KeyFeatures"))
const HowItWorks = loadable(() => import("../components/HomePage/HowItWorks"))
const Testimonial = loadable(() => import("../components/HomePage/Testimonial"))
const CallToAction = loadable(() =>
  import("../components/HomePage/CallToAction")
)

export default function Home() {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={`YaraConnect - ${t("home-page.banner-section.title")}`} />
      <Banner />
      <KeyFeatures fallback={<div>Loading...</div>} />
      <HowItWorks fallback={<div>Loading...</div>} />
      <Testimonial fallback={<div>Loading...</div>} />
      <CallToAction fallback={<div>Loading...</div>} />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
